<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-07-06 13:47:21
-->
<template>
	<div class="addAc">
		<div class="addAc-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">共建基地</span>
					<el-input readonly="readonly" class="common-row-ipt" placeholder="请输入"
						v-model="addAc.comBaseName" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input class="common-row-ipt" placeholder="请输入" v-model="addAc.activityName" />
				</div>
			</div>
			<div class="activityAdd-timeAreas">
				<div class="activityAdd-timeAreas-content">
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">开始时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd" v-model="addAc.starTime"
								type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
						<span style="font-size: 14px;font-family: PingFang SC;font-weight: 400;margin: 0 10px;">~</span>
						<div class="common-row-item">
							<span class="common-row-label">结束时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd" v-model="addAc.endTime"
								type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
					</div>
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">活动地址</span>
							<div style="display: flex;align-items: center;width: 100%;">
								<el-input class="common-row-ipt" placeholder="请输入活动地址" v-model="addAc.address" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="activityAdd-editor">
				<div class="activityAdd-editor-title">
					活动详情
				</div>
				<quillEditor @editorChange="editorChange" :intEditor.sync="addAc.activityDetails"
					class="handle_form_editor" />
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">工时设定</span>
					<div class="common-row-time">
						<div class="row-time-val">
							<div class="time-val-item">
								<span class="val-item-label">核定每日活动人数上限</span>
								<el-input v-model="addAc.maxNumPeople" class="val-item-ipt" placeholder="请输入" />
							</div>
							<div class="time-val-item">
								<span class="val-item-label">核定单人单日工时数上限</span>
								<el-input v-model="addAc.maxOneWorkingHours" class="val-item-ipt" placeholder="请输入" />
							</div>
							<div class="time-val-item">
								<span class="val-item-label">核定活动单日总工时数上限</span>
								<el-input v-model="addAc.maxWorkingHours" class="val-item-ipt" placeholder="请输入" />
							</div>
						</div>
						<div class="row-time-mark">
							<span class="time-mark-label">注：</span>
							<div class="time-mark-content">
								<span>核定每日活动人数上限指该共建基地下的单日活动的工时总条数的上限。（限整数）</span>
								<span>核定单人单日工时数上限指该共建基地下的单日活动的单日单条工时记录的工时数上限。（限整数/一位小数）</span>
								<span>核定活动单日总工时数上限指该共建基地下的单日活动的所有志愿者工时数之和。（限整数/一位小数）</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="addAc-btn">
				<el-button v-preventReClick @click="submitVerify" type="primary">编辑</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import quillEditor from "@/components/quillEditor.vue";
	import storage from 'store';
	import {
		editActivity,
		getActivityDetail,
	} from "@/api/activity";
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				addAc: {
					activityType: "2",
					comBaseId: "",
					comBaseName: "",
					activityName: "",
					adressList: [{
						activityAdress: [{
							address: ""
						}],
						activityTimeStart: "",
						activityTimeEnd: ""
					}],
					activityDetails: "",
					organizer: "",
					maxWorkingHours: "",
					maxOneWorkingHours: "",
					maxNumPeople: "",
					activityId: "",
				},
			};
		},
		mounted() {
			this.activityId = this.$route.query.activityId
			this.activityDetailFn()
		},
		methods: {
			editorChange(props) {
				console.log("editorChange", props)
				this.addAc.activityDetails = props
			},
			// 获取活动详情
			activityDetailFn() {
				getActivityDetail(this.activityId, {
					id: this.activityId
				}).then((res) => {
					if (res.code == 0) {
						this.addAc = res.data
					}
				})
			},
			workingHoursCheck(type, intNum) {
				if (intNum) {
					let regx1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/;
					let regx2 = /^[1-9]+[0-9]*$/;
					if (type == 'point') {
						if (regx1.test(intNum)) {
							let pointIndex = intNum.lastIndexOf('.')
							if (pointIndex != -1) {
								let regx3 = /^\d+(\.\d{1,1})?$/
								if (regx3.test(intNum)) {
									let pointNum = intNum.slice(pointIndex + 1, intNum.length)
									console.log("pointNum", pointIndex, pointNum)
									if ((pointNum * 1) == 5) {
										return true
									} else {
										return false
									}
								} else {
									return false
								}
							} else {
								return true
							}
						} else {
							return false
						}
					} else {
						if (regx2.test(intNum)) {
							return true
						} else {
							return false
						}
					}
				} else {
					return false
				}
			},
			submitVerify() {
				console.log("submitVerify", this.addAc)
				let activityData = JSON.parse(JSON.stringify(this.addAc))
				if (!activityData.activityName) {
					this.$message.error("请填写活动名称~")
					return
				}
				if (!activityData.starTime || !activityData.endTime) {
					this.$message.error("请选择活动时间~")
					return
				} else {
					if (new Date(activityData.starTime).getTime() > new Date(activityData.endTime).getTime()) {
						this.$message.error("活动开始时间必须小于活动结束时间~")
						return
					}
				}
				if (!activityData.address) {
					this.$message.error("请完善活动地址~")
					return
				}
				if (!activityData.activityDetails) {
					this.$message.error("请填写活动策划~")
					return
				}
				if (!this.workingHoursCheck('integer', activityData.maxNumPeople + '')) {
					this.$message.error("核定每日活动人数上限数值不规范~")
					return
				}
				if (!this.workingHoursCheck('point', activityData.maxOneWorkingHours + '')) {
					this.$message.error("核定单人工时数上限不规范~")
					return
				}
				if (!this.workingHoursCheck('point', activityData.maxWorkingHours + '')) {
					this.$message.error("核定活动总工时数上限不规范~")
					return
				}
				editActivity(activityData.id, activityData).then((res) => {
					if (res.code == 0) {
						this.$message.success("编辑共建基地活动成功~")
						this.$router.go(-1)
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	.addAc {
		.addAc-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 20px;

				.common-row-delete {
					width: 124px;
					height: 36px;
					border: 1px solid #EDF1F7;
					border-radius: 0px 2px 2px 0px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-left: 10px;

					img {
						width: 18px;
						height: 18px;
						margin-bottom: 4px;
						margin-right: 8px;
					}

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #303133;
					}
				}

				.common-row-ipt {
					flex: 1;
				}

				.common-row-select {
					width: 476px;
				}

				.common-row-date {
					width: 100%;
				}
			}

			.activityAdd-editor {
				display: flex;
				flex-direction: column;
				margin-bottom: 18px;

				.activityAdd-editor-title {
					background: #F6F7F9;
					border: 1px solid #EDF1F7;
					border-radius: 2px 2px 0px 0px;
					line-height: 36px;
					padding: 0 22px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #606266;
				}
			}

			.activityAdd-timeAreas {
				display: flex;
				align-items: stretch;

				.activityAdd-timeAreas-content {
					flex: 1;
					display: flex;
					flex-direction: column;
				}

				.activityAdd-timeAreas-delete {
					width: 128px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					margin-left: 5px;
					cursor: pointer;
					border: 1px solid #EDF1F7;
					border-radius: 0px 2px 2px 0px;
					margin-bottom: 20px;

					.timeAreas-delete-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						img {
							width: 18px;
							height: 18px;
							margin-bottom: 4px;
						}

						span {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 20px;
							color: #303133;
						}
					}
				}
			}

			.activityAdd-btn {
				width: 100%;
				height: 36px;
				border: 1px solid #EDF1F7;
				border-radius: 0px 2px 2px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #1CABB9;
				margin: 0 0 16px 0;
				cursor: pointer;
			}
		}
	}
</style>
